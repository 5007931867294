import "@scss/style.scss";

import LazyLoad from "vanilla-lazyload";
import $ from "jquery";
import { Collapse } from "bootstrap";
import mediumZoom from "medium-zoom";

$(function () {
    const navLinks = document.querySelector(".navbar-toggler");
    const menuToggle = document.getElementById("navbarSupportedContent");
    const bsCollapse = new Collapse(menuToggle, { toggle: false });
    navLinks.addEventListener("click", () => {
        bsCollapse.toggle();
    });

    $("#navbarDropMobile").on("click", function () {
        $(".dropdown-menu").parent().toggleClass("open");
    });

    $("h1, h2, h3, h4, h5, h6").hover(
        function () {
            $(this).find(".anchor").show();
        },
        function () {
            $(this).find(".anchor").hide();
        }
    );

    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy",
    });

    $(".appTitle").hover(function () {
        $(".appDetail").hide();
        var appDetailID = $(this).data("target");
        $(".appDetail").html($(appDetailID).html());
        $(".appDetail").show();
    });

    $(".appTitle").mouseleave(function () {
        $(".appDetail").hide();
    });

    $(".appTitle").click(function (e) {
        e.preventDefault();
        var appSubmenuUrl = $(this).attr("href");
        window.open(appSubmenuUrl, "_blank");
    });

    $("div.appStore").click(function (e) {
        e.preventDefault();
        var appsUrl = $(this).data("url");
        window.open(appsUrl, "_blank");
    });
});

$(document).ready(function () {
    let zoomable_images = [];
    $("img").each(function (index, element) {
        if (!$(element).parent().is("a")) {
            zoomable_images.push(element);
        }
    });
    mediumZoom(zoomable_images, {
        background: "rgba(25, 18, 25, 0.65)",
    });
});

$(window).on("scroll", function (e) {
    if ($(window).scrollTop() == 0) {
        $("header").removeClass("scroll");
        return;
    }
    $("header").addClass("scroll");

});